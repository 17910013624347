import { Link } from "react-router-dom";
import { StatementType } from "./utils";
import { formatCurrency } from "../../utils/currency";
import { CButton } from "@coreui/react";
import { formatUTCDate } from "./../../utils/string";
import Badge from "./../../components/Badge";

export const COLUMNS = [
  {
    key: "invoiceNumber",
    title: "Statement Number",
    render: (invoiceNumber: string) => <span>{invoiceNumber}</span>,
  },
  {
    key: "invoiceDate",
    title: "Statement Date",
    render: (invoiceDate: string) => <span>{formatUTCDate(invoiceDate)}</span>,
  },
  {
    key: "billingPeriod",
    title: "Billing Period",
    render: (_: any, record: StatementType) => {
      return (
        <span>
          {formatUTCDate(record.billingStartDate)}
          {" - "}
          {formatUTCDate(record.billingEndDate)}
        </span>
      );
    },
  },
  {
    key: "paymentDueDate",
    title: "Due Date",
    render: (paymentDueDate: string) => (
      <span>{formatUTCDate(paymentDueDate)}</span>
    ),
  },
  {
    key: "invoiceAmount",
    title: "Original Balance",
    render: (invoiceAmount: string) => (
      <span>{formatCurrency(invoiceAmount)}</span>
    ),
  },
  {
    key: "remainingBalance",
    title: "Amount Due",
    render: (remainingBalance: string) => (
      <span>{formatCurrency(remainingBalance)}</span>
    ),
  },
  {
    key: "invoiceStatus",
    title: "Status",
    render: (invoiceStatus: string) => <Badge status={invoiceStatus} />,
  },
  {
    key: "invoiceLink",
    title: "King Energy Statement",
    render: (invoiceLink: any) => (
      <Link to="/statement" state={{ link: invoiceLink }}>
        <CButton style={{ whiteSpace: "nowrap" }}>View</CButton>
      </Link>
    ),
  },
  {
    key: "invoiceExternalLink",
    title: "Original Utility Statement",
    render: (invoiceExternalLink: any) => {
      if (!invoiceExternalLink) return <span>Not Available</span>;

      return (
        <Link to="/statement" state={{ link: invoiceExternalLink }}>
          <CButton style={{ whiteSpace: "nowrap" }}>View</CButton>
        </Link>
      );
    },
  },
];
