import classnames from "classnames";

interface PropTypes {
  status: string;
  className?: string;
}

export default function Badge(props: PropTypes) {
  const { status, className, ...rest } = props;

  const defaultStatusColorMap = {
    secondary: ["VOID"],
    danger: [
      "FAILED",
      "PAST_DUE",
      "CHARGEBACK",
      "CANCELLED",
      "DISABLED",
      "BALANCE_CARRY_FORWARD",
    ],
    warning: ["PENDING", "PROCESSING"],
    success: [
      "ACCEPTED",
      "SUCCEEDED",
      "PAID",
      "PAID_EXTERNALLY",
      "CONSUMED",
      "ENABLED",
      "CURRENT",
    ],
  };

  if (!status) {
    return null;
  }

  return (
    <span
      className={classnames(
        "badge",
        {
          "bg-secondary text-dark":
            defaultStatusColorMap.secondary.includes(status),
          "bg-danger text-white": defaultStatusColorMap.danger.includes(status),
          "bg-warning text-white":
            defaultStatusColorMap.warning.includes(status),
          "bg-success text-white":
            defaultStatusColorMap.success.includes(status),
        },
        className
      )}
      {...rest}
    >
      {status.replace(/'_'/g, " ")}
    </span>
  );
}
